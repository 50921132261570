import React from "react"
import PageHero from "../../components/pagehero"
import Footer from "../../components/footer"
import AppsContent from "../../components/expertise-apps"
import MobileHeader from "../../components/MobileHeader"

const ServicesApps = () => {
  return (
    <div>
      <MobileHeader></MobileHeader>
      <PageHero
        heading={["Application Design and Development" ]}
        description="Building applications that solve real problems delightfully. "
        imgSrc="../../app-bg.jpg"
        pageTitle="Apps"
      ></PageHero>
      <AppsContent />
      <Footer></Footer>
    </div>
  )
}

export default ServicesApps
