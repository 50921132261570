import React from "react"
import Styles from "../styles/components/Expertise.module.scss"
import ScrollAnimation from "react-animate-on-scroll"

const ExpertiseBrands = (props) => (
  <div className={Styles["plat-spacing"]}>
    <br />
    <h2 className={Styles["plat-header-text"]}>
      A few of the platforms and technologies we work with...
    </h2>

    <div>
      <ScrollAnimation animateOnce animateIn="zoomInUp">
        {props.website && <div className={Styles["logo-image"]}>
          <div>Gatsby</div>
          <div>WordPress</div> 
          <div>Webflow</div> 
          <div>Shopify</div>
          <div>SquareSpace</div>
          <div>HTML</div>
          <div>CSS</div>
          <div>SCSS</div>
          <div>React</div>
          <div>SQL</div>
          <div>Contentful</div>
          <div>Netlify</div>
        </div>}
        {props.apps && <div className={Styles["logo-image"]}>
          <div>React Native</div>
          <div>Vue</div>
          <div>Electron</div>
          <div>Flutter</div>
          <div>React</div>
          <div>ionic</div>
          <div>Postgres</div>
          <div>Mongo</div>
          <div>NodeJS</div>
          <div>PHP</div>
          <div>Laravel</div>
          <div>Firebase</div>
          <div>Javascript</div>
          <div>Python</div>
        </div>}
        {props.tech && <div className={Styles["logo-image"]}>
            <div>AWS</div>
            <div>Azure</div>
            <div>Google Cloud</div>
            <div>Tensor Flow</div>
            <div>Power BI</div>
            <div>SQL</div>
          </div>}
      </ScrollAnimation>
    </div>
  </div>
)

export default ExpertiseBrands
