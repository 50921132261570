import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { useStaticQuery, graphql } from "gatsby"
import WorkCard from "./WorkCard"
import HomeStyles from "../styles/components/HomeWorkSection.module.scss"
import Styles from "../styles/components/Expertise.module.scss"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PlatformImages from "../components/expertisePlatform"

const ExpertiseWebsite = () => {
  const data = useStaticQuery(graphql`
    query appsCaseStudySection {
      allCaseStudiesJson(
        limit: 2
        filter: { frontmatter: { workType: { eq: "app" } } }
      ) {
        edges {
          node {
            id
            challenge
            outcome
            frontmatter {
              slug
              workTitle
              featureImage
              companyName
              workType
            }
          }
        }
      }
    }
  `)
  const latestPosts = data.allCaseStudiesJson.edges.filter(
    caseStudy => caseStudy.node.frontmatter.workType == "app"
  )
  return (
    <div>
      <div
        className={`${Styles["background"]} ${
          Styles["section-container"]
        } container-padding`}
      >
        <div className={Styles["section-line--content-container"]}>
          <div className={Styles["section-line--title"]}>
            <h5>Approach</h5>
          </div>
          <div className={Styles["section-divider"]}>
            <div className={Styles["section-divider--text"]}>
              <h1 className={Styles["section-title--big"]}>App Development</h1>
              <ScrollAnimation animateIn="fadeIn">
                <div className={Styles["section-text--divider"]}>
                  <p>
                    Think about how much time it takes you today to order food,
                    shop for clothes or take a ride.{" "}
                    <span>
                      Technology and applications have changed the way we live,
                      we are able to do things faster and easier because of
                      these applications, now imagine implementing similar
                      software to your business.
                    </span>{" "}
                    Where you are able to product new value points to your
                    customers and automate previously difficult tasks and
                    activities.
                    <br />
                    <br />
                    Our goal with applications is to help you launch new digital
                    products that provide new revenue opportunities and make
                    difficult work fast. Your business gets to save and make
                    more revenue while doing less strenous work.
                  </p>
                </div>
              </ScrollAnimation>
              <br />
              <div className={Styles["services-subsection-container"]}>
                <h4>Key Deliverables</h4>
                <div className={Styles["services-subsection"]}>
                  <div className={Styles["services-subsection--service"]}>
                    <h3>Strategy</h3>
                    <p>Business Pain Points</p>
                    <p>Business Opportunities</p>
                    <p>Design Thinking</p>
                    <p>Product Strategy</p>
                    <p>Product Roadmap</p>
                  </div>
                  <div className={Styles["services-subsection--service"]}>
                    <h3>Design</h3>
                    <p>Product Design </p>
                    <p>UI/UX Design</p>
                    <p>Brand Identity Design</p>
                    <p>Design Sprints</p>
                    <p>Documentation Design</p>
                  </div>
                  <div className={Styles["services-subsection--service"]}>
                    <h3>Development</h3>
                    <p>Database Development</p>
                    <p>Backend Development</p>
                    <p>Frontend Development</p>
                    <p>Mobile App Development</p>
                    <p>Desktop App Development</p>
                  </div>
                  <div className={Styles["services-subsection--service"]}>
                    <h3>Deployment</h3>
                    <p>Dev Ops</p>
                    <p>App Analytics</p>
                    <p>Hosting</p>
                    <p>Training</p>
                    <p>Maintenance</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div>
        <div className={Styles["process-section"]}>
          <div className={`${Styles["section-line--title"]} container-padding`}>
            <h5 className={Styles["section-line--dark"]}>Process</h5>
          </div>
          <div
            className={`${
              Styles["process-section-container"]
            } container-padding`}
          >
            <h3>App Design Process</h3>
            <p>
              Every app is unique we think of building applications like
              buildings, we need to understand the purpose, the problem and the
              future projections so we know what to prepare for.
            </p>

            <div className={Styles["wrap-collabsible"]}>
              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> User and Problem Insights
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    Who will be using the app and for what? What context would
                    they be using it and why do we think an app would solve
                    their problem. These are the questions we try to answer to
                    create a vision for what the app is and what it will be.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> App Strategy
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    Our goal here is to provide solutions for how the app would
                    be used, to give context on actions, stories and journeys
                    that the user would take. We typically use sketches to
                    describe how the underlying problem would be solved by the
                    application.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Idea Generation
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    From seeing the stories and giving context we begin to
                    generate ideas and wireframes to accompany those ideas that
                    would be deliberated on. We work with you to test those
                    ideas on your users for validation and select the most
                    viable for work.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> MVP Creation
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    Once we're settled on an idea we go ahead to implement the
                    MVP, taking into account the core of the application, we
                    build a usable version within short time the user has a real
                    application to test.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Interation and Enhancement
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    We take the feedback from the MVP and add the necessary
                    corrections and new features for a proper launch and use.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Monitor and Evolve
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    We continuously run sprints to add new features, maintain
                    the application and ensure the applicaiton is delivering to
                    full capacity
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-padding">
          {" "}
          {/* the platform logos */}
          <PlatformImages apps />
        </div>
        <div className="container-padding">
          {latestPosts.length !== 0 && (
            <div>
              <div className={Styles["section-line--title--dark"]}>
                <h5>Work</h5>
              </div>
              <div>
                <div className={Styles["case-study-area"]}>
                  <h2 className={Styles["featured-head-text"]}>
                    Featured Case Studies
                  </h2>
                  <div className={HomeStyles["section-portfolio"]}>
                    {latestPosts.map(({ node: post }) => (
                      <WorkCard
                        key={post.id}
                        companyName={post.frontmatter.companyName}
                        workTitle={post.frontmatter.workTitle}
                        imageSrc={post.frontmatter.featureImage}
                        slug={post.frontmatter.slug}
                      ></WorkCard>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ScrollAnimation animateIn="bounceIn">
        <AniLink
          cover
          to="/expertise/tech"
          direction="right"
          bg="rgba(0, 0, 0)"
          duration={0.3}
          activeClassName="active"
        >
          <div className={Styles["expertise-next-section"]}>
            <p>Next </p>
            <h4>Technology Development</h4>
          </div>
        </AniLink>
      </ScrollAnimation>
    </div>
  )
}

export default ExpertiseWebsite
